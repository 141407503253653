import {USER_UNNAMED} from 'constants/constants';
import userServices from 'store/userServices';
import React from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import AnonimAva from 'assets/images/anonim-ava.jpg';
import './profile-main.scss';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import Button from 'components/hoc/Button';

interface IProfileMainScreenProps {
	setCurrentScreen: (val: string) => void;
}

const ProfileMainScreen: React.FC<IProfileMainScreenProps> = function ProfileMainScreen({
	setCurrentScreen,
}) {
	const {userData} = useLocalObservable(() => userServices);
	const translations = useL10n();
	const {sendAnalytics} = useAnalytics();

	const clickOnchageAvatarHandler = () => {
		sendAnalytics('change_avatar_pressed');
		setCurrentScreen('avatar');
	};

	const clickOnchageNameHandler = () => {
		sendAnalytics('change_name_pressed');
		setCurrentScreen('name');
	};

	return (
		<section className='profile-main profile-screens'>
			<p className='profile-screens__title'>{translations.profileScreen.title}</p>
			<div className='profile-main__avatar'>
				<div
					className='profile-main__avatar-image'
					style={{
						backgroundImage: `url(${userData && userData.pic !== '' ? userData.pic : AnonimAva})`,
					}}
				/>
				<Button className='profile-main__avatar-change' onClick={clickOnchageAvatarHandler}>
					{translations.profileScreen.selectAnotherPic}
				</Button>
			</div>
			{/* <div className='profile-main__name'>
				<p className='profile-main__label'>{translations.profileScreen.chatNameLabel}</p>
				<div className='profile-main__input'>
					<span className='profile-main__input-text'>
						{userData && userData.name !== '' ? userData.name : USER_UNNAMED}
					</span>
					<Button className='profile-main__input-edit' onClick={clickOnchageNameHandler}>
						{translations.profileScreen.changeName}
					</Button>
				</div>
			</div> */}
		</section>
	);
};

export default observer(ProfileMainScreen);
