import {AlertBtnType} from 'models/enums/Alert.enum';
import SocketIoServices from 'services/SocketIoServices';
import appService from 'store/appService';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import alertService from 'store/alertService';
import toastService from 'store/toastService';
import useMessage from 'hooks/useMessage';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import useL10n from 'l10n/useL10n';
import Button from 'components/hoc/Button';
import {ToastIconName} from 'components/toasts/ToastIcons';
import {ReactComponent as Pin} from 'assets/svg/ico-pin.svg';
import './pinnedMessage.scss';

interface IPinnedMessageProps {
	handleClick: (messageId: number) => void;
}

const PinnedMessage: FunctionComponent<IPinnedMessageProps> = function PinnedMessage(props) {
	const {handleClick} = props;
	const {appReadOnly} = useLocalObservable(() => appService);
	const {isUserExternalIdCorrect} = useLocalObservable(() => userServices);
	const {roomId, myTalker, pinnedMessage} = useLocalObservable(() => roomServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);
	const {addToast} = useLocalObservable(() => toastService);
	const translations = useL10n();
	const {getMessageText} = useMessage();

	const talkerIsModer = !!myTalker?.isModer;
	const pinnedMessageAdvertisementText =
		pinnedMessage?.advertisement?.title || pinnedMessage?.advertisement?.text;

	const unpinMessage = () => {
		if (!appReadOnly && isUserExternalIdCorrect && roomId) {
			showAlert({
				title: translations.alerts.unPinMessage.title,
				buttons: [
					{
						text: translations.alerts.btns.unpin,
						type: AlertBtnType.NORMAL,
						onPress: () => {
							SocketIoServices.emitPinMessage(roomId, null);
							addToast({
								iconName: ToastIconName.unPin,
								liveTime: 3000,
								message: translations.toasts.messageUnpinned,
								cancellable: true,
							});
							hideAlert();
						},
					},
					{
						text: translations.alerts.btns.cancel,
						type: AlertBtnType.DESTRUCTIVE,
						onPress: hideAlert,
					},
				],
				closeOnBackdropPress: true,
			});
		}
	};

	const clickOnPinnedMessageHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect && pinnedMessage !== null) {
			handleClick(pinnedMessage.id);
		}
	};

	return (
		<div className='pinned-message'>
			<div className='pinned-message__container'>
				<Button onClick={clickOnPinnedMessageHandler} className='pinned-message__scrollbtn'>
					Scroll
				</Button>

				<Pin className='pinned-message__icon' />
				<div className='pinned-message__body'>
					<p className='pinned-message__title'>{translations.pinnedMessage.title}</p>
					<p className='pinned-message__text'>
						{(pinnedMessage && getMessageText(pinnedMessage)) || pinnedMessageAdvertisementText}
					</p>
				</div>
			</div>
			{talkerIsModer && (
				<Button className='pinned-message__unpin' onClick={unpinMessage}>
					Unpin
				</Button>
			)}
		</div>
	);
};

export default observer(PinnedMessage);
