import useL10n from 'l10n/useL10n';
import {FunctionComponent, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {ReactComponent as IcoWarning} from 'assets/svg/ico-warn.svg';

interface IErrorProps {
	errorNumber?: number;
}

const Error: FunctionComponent<IErrorProps> = function Error({errorNumber}) {
	const translations = useL10n();
	const {search} = useLocation();
	const userId = new URLSearchParams(search).get('userId');
	const roomId = new URLSearchParams(search).get('roomId');
	const errorNumberFormUrl = new URLSearchParams(search).get('errorNumber');
	const [errorNumberLocal, setErrorNumberLocal] = useState(
		errorNumber || (errorNumberFormUrl && parseInt(errorNumberFormUrl, 10)) || 0
	);

	useEffect(() => {
		if (errorNumberLocal < 3) {
			if (userId === '') {
				setErrorNumberLocal(0);
				roomId === null && setErrorNumberLocal(2);
				return;
			}
			roomId === null && setErrorNumberLocal(1);
		}
	}, [roomId, userId]);

	if (errorNumberLocal > translations.errorPage.types.length - 1) {
		setErrorNumberLocal(0);
		return null;
	}

	return (
		<div className='error'>
			<div className='error__header'>
				<IcoWarning />
				<div className='error__container'>
					<p className='error__title'>{translations.errorPage.types[errorNumberLocal].title}</p>
					<p className='error__subtitle'>{translations.errorPage.error}</p>
				</div>
			</div>
			<p className='error__text'>{translations.errorPage.types[errorNumberLocal].text}</p>
		</div>
	);
};

export default observer(Error);
