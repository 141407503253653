import classNames from 'classnames';
import {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {CSSTransition} from 'react-transition-group';
import {getParent} from 'utils/helpers';
import usePollAnalytics from 'hooks/usePollAnalytics';
import appService from 'store/appService';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import modalServices from 'store/modalServices';
import pollServices from 'store/pollServices';
import useL10n from 'l10n/useL10n';
import Button from 'components/hoc/Button';

import {ReactComponent as IcoClose} from 'assets/svg/ico-close.svg';

import PollToggleOptions from './PollToggleOptions';
import PollToggleSubmenu from './PollToggleSubmenu';
import './pollToggle.scss';

const POLL_TOGGLE_BUTTON_ACCENT_ADD_TIMEOUT = 4000;
const POLL_TOGGLE_BUTTON_ACCENT_REMOVE_TIMEOUT = 8000;
const POLL_TOGGLE_TOOLTIP_VISIBILITY_DURATION = 350;

const PollToggle: FunctionComponent = function PollToggle() {
	const pollToggleButtonAccentAddRef: {current: NodeJS.Timeout | null} = useRef(null);
	const pollToggleButtonAccentRemoveRef: {current: NodeJS.Timeout | null} = useRef(null);
	const {poll: pollTranslate} = useL10n();
	const {sendPollAnalytics} = usePollAnalytics();
	const [visiblePollToggleSubmenu, setVisiblePollToggleSubmenu] = useState(false);
	const {appReadOnly} = useLocalObservable(() => appService);
	const {userData, isUserExternalIdCorrect} = useLocalObservable(() => userServices);
	const {myTalker, pinnedMessage, restApiDataLoaded} = useLocalObservable(() => roomServices);
	const {
		poll,
		pollAlreadyVoted,
		pollTooltipVisible,
		pollToggleButtonAccent,
		toggllePollTooltipVisible,
		toggllePollToggleButtonAccent,
	} = useLocalObservable(() => pollServices);
	const {togglePollModalVisible} = useLocalObservable(() => modalServices);

	const userIdValid = userData && userData.externalId !== '0';
	const isMyTalkerModer = !!myTalker?.isModer;
	const checkVisibleToggleOptions =
		poll &&
		poll.options.length &&
		!poll.options.filter(option => option.pic === '' || option.pic === null).length;

	const pollToggleClasses = classNames('poll-toggle', {
		'poll-toggle--active': poll && !pollAlreadyVoted,
		'poll-toggle--down': pinnedMessage,
	});

	const pollToggleButtonContentClasses = classNames('poll-toggle__button-content', {
		'poll-toggle__button-content--accent': pollToggleButtonAccent && !pollTooltipVisible,
	});

	const onPollToggleHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect) {
			if (isMyTalkerModer) {
				setVisiblePollToggleSubmenu(!visiblePollToggleSubmenu);
				return;
			}
			toggllePollTooltipVisible(false);
			togglePollModalVisible(true);
			pollAlreadyVoted ? sendPollAnalytics('pq_viewed') : sendPollAnalytics('pq_opened');
		}
	};

	const onClickEventHandler = (event: any) => {
		if (
			!appReadOnly &&
			isUserExternalIdCorrect &&
			!getParent(event.target, 'poll-toggle__submenu')
		) {
			setVisiblePollToggleSubmenu(false);
		}
	};

	const onPollToggleTooltipCloseHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect) {
			toggllePollTooltipVisible(false);
		}
	};

	const clearAllpollToggleButtonAccentTimeouts = () => {
		if (pollToggleButtonAccentAddRef.current) {
			clearTimeout(pollToggleButtonAccentAddRef.current);
		}
		if (pollToggleButtonAccentRemoveRef.current) {
			clearTimeout(pollToggleButtonAccentRemoveRef.current);
		}
	};

	const pollToggleTooltip = useCallback(
		() => (
			<div className='poll-toggle__tooltip'>
				<Button className='poll-toggle__tooltip-close' onClick={onPollToggleTooltipCloseHandler}>
					<IcoClose />
				</Button>
				<div className='poll-toggle__tooltip-content'>
					<div className='poll-toggle__tooltip-text'>{poll?.text}</div>
					<Button className='poll-toggle__tooltip-vote' onClick={onPollToggleHandler}>
						{pollTranslate.tooltip.btn}
					</Button>
				</div>
			</div>
		),
		[poll?.text]
	);

	useEffect(() => {
		if (restApiDataLoaded.pollLoaded) {
			if (!pollAlreadyVoted && !pollTooltipVisible && !isMyTalkerModer) {
				pollToggleButtonAccentAddRef.current = setTimeout(() => {
					if (!pollAlreadyVoted && !pollTooltipVisible && !isMyTalkerModer) {
						toggllePollToggleButtonAccent(true);
						pollToggleButtonAccentRemoveRef.current = setTimeout(() => {
							toggllePollToggleButtonAccent(false);
						}, POLL_TOGGLE_BUTTON_ACCENT_REMOVE_TIMEOUT);
						return;
					}
					clearAllpollToggleButtonAccentTimeouts();
				}, POLL_TOGGLE_BUTTON_ACCENT_ADD_TIMEOUT);
			}
		}

		return () => {
			clearAllpollToggleButtonAccentTimeouts();
		};
	}, [restApiDataLoaded.pollLoaded, isMyTalkerModer]);

	useEffect(() => {
		if (pollTooltipVisible) {
			toggllePollToggleButtonAccent(false);
			clearAllpollToggleButtonAccentTimeouts();
		}
	}, [pollTooltipVisible]);

	useEffect(() => {
		if (visiblePollToggleSubmenu) {
			document.addEventListener('touchstart', onClickEventHandler);
			document.addEventListener('mousedown', onClickEventHandler);
		}

		return () => {
			document.removeEventListener('touchstart', onClickEventHandler);
			document.removeEventListener('mousedown', onClickEventHandler);
		};
	}, [visiblePollToggleSubmenu]);

	return (
		<div className={pollToggleClasses}>
			<Button className='poll-toggle__button' onClick={onPollToggleHandler}>
				<div className={pollToggleButtonContentClasses}>
					<div className='poll-toggle__button-ico' />
					<div className='poll-toggle__button-text'>{pollTranslate.btns.vote}</div>
				</div>
				{!!checkVisibleToggleOptions && <PollToggleOptions />}
			</Button>
			{isMyTalkerModer && visiblePollToggleSubmenu && (
				<PollToggleSubmenu callBackFunctionAfterClick={setVisiblePollToggleSubmenu} />
			)}
			<CSSTransition
				in={pollTooltipVisible && !pollAlreadyVoted && !isMyTalkerModer}
				timeout={POLL_TOGGLE_TOOLTIP_VISIBILITY_DURATION}
				classNames='fade'
				unmountOnExit>
				{pollToggleTooltip()}
			</CSSTransition>
		</div>
	);
};

export default observer(PollToggle);
