/* eslint-disable jsx-a11y/label-has-associated-control */
import {FunctionComponent} from 'react';
import {v4 as uuidv4} from 'uuid';
import './checkbox.scss';

interface ICheckboxProps {
	checked: boolean;
	text?: string;
	onChange?: () => void;
}

const Checkbox: FunctionComponent<ICheckboxProps> = function Checkbox({text, checked, onChange}) {
	const id = uuidv4();
	return (
		<div className={`checkbox ${!text && 'checkbox--min'}`}>
			<input
				id={id}
				type='checkbox'
				className='checkbox__input'
				defaultChecked={checked}
				onChange={onChange}
			/>
			<label className='checkbox__label' htmlFor={id}>
				{text || ''}
			</label>
		</div>
	);
};

export default Checkbox;
