import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import axios from 'axios';
import {apiPath} from 'constants/api';
import {AnalyticsData} from 'models/responses';
import {TechnicalAnalyticsProps, AnalyticsProps} from 'models/room';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import Log from 'utils/log';
import checkResponse from 'utils/checkResponse';

export default class AnalyticServices {
	static sendTechnicalAnalytics = async (analyticsData: TechnicalAnalyticsProps) => {
		try {
			const params = {
				...analyticsData,
				key: 'JGMHFqonZuwKeoly+bzNnw==',
			};
			const {data, status} = await axios.post<AnalyticsData>(apiPath.statEvent, params);
			const checkData = data || 'empty';
			checkResponse(checkData, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			Log.error('Send technical analytics error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static sendAnalytics = async (analyticsData: AnalyticsProps) => {
		try {
			const params = {
				...analyticsData,
				key: 'JGMHFqonZuwKeoly+bzNnw==',
			};
			const {data, status} = await axios.post<AnalyticsData>(apiPath.stat, params);
			const checkData = data || 'empty';
			checkResponse(checkData, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			Log.error('Send analytics error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}
