/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {MODAL_STYLE} from 'constants/constants';
import {OnboardStage} from 'models/enums/OnboardStage.enum';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import useL10n from 'l10n/useL10n';
import useSocketsHandlers from 'hooks/useSocketsHandlers';
import appService from 'store/appService';
import userServices from 'store/userServices';
import modalServices from 'store/modalServices';
import UserService from 'services/api/UserService';
import {FunctionComponent, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import Button from 'components/hoc/Button';
import {Checkbox} from 'components/ui';

const TemporaryAgreementModal: FunctionComponent = function TemporaryAgreementModal() {
	const {modals} = useL10n();
	const [checkboxChecked, setCheckboxChecked] = useState(true);
	const {rules} = useLocalObservable(() => appService);
	const {accessToken, updateUserData} = useLocalObservable(() => userServices);
	const {temporaryAgreementModal, toggleTemporaryAgreementModal, toggleRulesModal} =
		useLocalObservable(() => modalServices);
	const {showUserNameInChat} = useSocketsHandlers();

	const onShowRulesHandler = () => {
		toggleRulesModal(true);
	};

	const onCheckboxHandler = () => {
		setCheckboxChecked(!checkboxChecked);
	};

	const onAgreementHandler = async () => {
		if (accessToken) {
			const response = await UserService.patchUserData(
				{isOnboard: true, onboardStage: OnboardStage.NAME},
				accessToken
			);
			if (response.status === ResponseStatus.SUCCESS) {
				updateUserData({isOnboard: true, onboardStage: OnboardStage.NAME});
				toggleTemporaryAgreementModal(false);
				showUserNameInChat();
			}
		}
	};

	return (
		<Modal
			className='modal modal--agreement'
			isOpen={temporaryAgreementModal}
			ariaHideApp={false}
			style={{
				overlay: {...MODAL_STYLE.overlay, zIndex: 30000},
			}}>
			<div className='modal__head'>
				<div className='modal__title'>{modals.temporaryAgreement.title}</div>
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<div className='short-rules'>
						<div className='short-rules__items'>
							<div className='short-rules__item'>
								<p className='short-rules__item-title'>
									{modals.temporaryAgreement.rules.bePolite.title}
								</p>
								<p className='short-rules__item-text'>
									{modals.temporaryAgreement.rules.bePolite.text}
								</p>
							</div>
							<div className='short-rules__item'>
								<p className='short-rules__item-title'>
									{modals.temporaryAgreement.rules.beAttentive.title}
								</p>
								<p className='short-rules__item-text'>
									{modals.temporaryAgreement.rules.beAttentive.text}
								</p>
							</div>
							<div className='short-rules__item'>
								<p className='short-rules__item-title'>
									{modals.temporaryAgreement.rules.notAdvertise.title}
								</p>
								<p className='short-rules__item-text'>
									{modals.temporaryAgreement.rules.notAdvertise.text}
								</p>
							</div>
						</div>
						{rules && (
							<Button className='short-rules__btn' onClick={onShowRulesHandler}>
								{modals.temporaryAgreement.btns.allRules}
							</Button>
						)}
					</div>
				</div>
			</div>
			<div className='modal__footer'>
				<div className='modal__footer-content'>
					<Checkbox checked={checkboxChecked} onChange={onCheckboxHandler} />
					<p
						className='modal__footer-text'
						dangerouslySetInnerHTML={{__html: modals.temporaryAgreement.text}}
					/>
				</div>
				<Button
					className='modal__footer-btn btn'
					onClick={onAgreementHandler}
					disabled={!checkboxChecked}>
					{modals.temporaryAgreement.btns.acceptAndContinue}
				</Button>
			</div>
		</Modal>
	);
};

export default observer(TemporaryAgreementModal);
