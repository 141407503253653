import {ToastMessage} from 'models/toast';
import toastService from 'store/toastService';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {CSSTransition} from 'react-transition-group';
import classNames from 'classnames';
import Button from 'components/hoc/Button';

import ToastIcon, {ToastIconName} from './ToastIcons';
import './toasts.scss';

const TOAST_TIME_TO_LIVE = 3000;
const INTERVAL_STEP = 200;

interface IToastProps {
	value: ToastMessage;
}

const Toast: FunctionComponent<IToastProps> = function Toast({value}) {
	const {deleteToast} = useLocalObservable(() => toastService);
	const [visibleToast, setVisibleToast] = useState(false);
	const [liveTime, setLiveTime] = useState(value.liveTime || TOAST_TIME_TO_LIVE);
	const [currentTimerId, setCurrentTimerId] = useState<NodeJS.Timer | null>(null);

	const color = value.messageColor ? {color: value.messageColor} : null;

	const toastClasses = classNames('toast', {
		'toast--have-img': value.imgSrc,
	});

	const cancelTimer = useCallback(() => {
		if (currentTimerId) {
			clearInterval(currentTimerId);
			if (value.onCancel) {
				value.onCancel();
			}
			setVisibleToast(false);
			return;
		}
		setVisibleToast(false);
	}, [currentTimerId, value]);

	useEffect(() => {
		setVisibleToast(true);
		let localLiveTime = liveTime;
		const timerId: NodeJS.Timer = setInterval(() => {
			if (localLiveTime <= 999) {
				clearInterval(timerId);
				setVisibleToast(false);
				value?.deathCallback && value.deathCallback();
				return;
			}
			localLiveTime -= INTERVAL_STEP;
			setLiveTime(localLiveTime);
		}, INTERVAL_STEP);
		setCurrentTimerId(timerId);
		return () => {
			if (timerId) {
				clearInterval(timerId);
			}
		};
	}, []);

	return (
		<CSSTransition
			in={visibleToast}
			timeout={500}
			classNames='fade'
			onExited={() => deleteToast(value)}
			unmountOnExit>
			<div className={toastClasses}>
				{value.imgSrc && (
					<div className='toast__overico' style={{backgroundImage: `url(${value.imgSrc})`}} />
				)}
				{!value.imgSrc && Object.keys(ToastIconName).find(key => key === value.iconName) && (
					<div className='toast__overico'>
						<ToastIcon name={value.iconName as ToastIconName} />
					</div>
				)}
				<p className='toast__text' style={{...color}}>
					{value.message}
				</p>
				{value.cancellable && (
					<Button className='toast__close-btn' onClick={cancelTimer}>
						<ToastIcon name={'close' as ToastIconName} />
					</Button>
				)}
			</div>
		</CSSTransition>
	);
};

export default observer(Toast);
