import {emotions} from 'data/emotions';
import {action, makeObservable, observable} from 'mobx';
import {Emotion} from 'models/room';

class EmotionServices {
	@observable
	public emotions: Emotion[] = emotions.filter(e => e.render);

	@observable
	public emotionsAnim: Emotion[] = [];

	@observable
	public emotionsVisible = false;

	@observable
	public idEmotionActive: UUID = emotions[0].id;

	@action
	public addEmotion = (emotion: Emotion) => {
		this.emotionsAnim = [...this.emotionsAnim, emotion];
	};

	@action
	public removeEmotion = (emotion: Emotion) => {
		this.emotionsAnim = this.emotionsAnim.filter(item => item.uuid !== emotion.uuid);
	};

	@action
	public toggleEmotionsVisible = (value: boolean) => {
		this.emotionsVisible = value;
	};

	@action
	public toggleIdEmotionActive = (emotionId: UUID) => {
		this.idEmotionActive = emotionId;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new EmotionServices();
