/* eslint-disable jsx-a11y/label-has-associated-control */
import {PollType, PollScreens} from 'models/enums/Poll.enum';
import pollService from 'store/pollServices';
import useL10n from 'l10n/useL10n';
import {FunctionComponent, useEffect} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {Switcher} from 'components/switcher';
import {ReactComponent as IcoNext} from 'assets/svg/ico-right-bubled-arrow.svg';
import Button from 'components/hoc/Button';

import './pollSettings.scss';

const PollSettings: FunctionComponent = function PollSettings() {
	const {poll: pollTranslate} = useL10n();
	const {
		pollCreated,
		pollWithPics,
		setScreen,
		updatePollCreated,
		setPollWithPics,
		resetIsRightPollCreatedOptions,
	} = useLocalObservable(() => pollService);

	useEffect(() => {
		if (pollCreated === null) {
			updatePollCreated({
				type: PollType.POLL,
				isMultiple: false,
			});
		}
	}, []);

	useEffect(() => {
		if (!pollWithPics.isChanged) {
			pollCreated?.options && pollCreated.options[0].pic !== null
				? setPollWithPics(true)
				: setPollWithPics(false);
		}
	}, [pollWithPics.isChanged]);

	const setMultipleHandler = (value: boolean) => {
		updatePollCreated({
			isMultiple: value,
		});
		resetIsRightPollCreatedOptions();
	};

	const setTypeHandler = (value: PollType) => {
		updatePollCreated({
			type: value,
		});
		resetIsRightPollCreatedOptions();
	};

	const changeWPicture = () => {
		setPollWithPics(!pollWithPics.value, true);
	};

	const nextStep = () => {
		setScreen(PollScreens.QUESTION);
	};

	return (
		<div className='poll-settings'>
			<div className='poll-settings__container'>
				<p className='poll-settings__title'>{pollTranslate.settings.title}</p>
				<div className='poll-settings__group'>
					<div className='poll-settings__item'>
						<p>{pollTranslate.settings.switches.quizMode}</p>
						<Switcher
							checked={pollCreated?.type === PollType.QUIZ}
							onChange={() =>
								setTypeHandler(pollCreated?.type === PollType.QUIZ ? PollType.POLL : PollType.QUIZ)
							}
						/>
					</div>
					<div className='poll-settings__item'>
						<p>{pollTranslate.settings.switches.multipleChoice}</p>
						<Switcher
							checked={!!pollCreated?.isMultiple}
							onChange={() => setMultipleHandler(!pollCreated?.isMultiple)}
						/>
					</div>
				</div>
				<p className='poll-settings__title'>{pollTranslate.settings.titleAdditionally}</p>
				<div className='poll-settings__group'>
					<div className='poll-settings__item'>
						<p>{pollTranslate.settings.switches.withPhoto}</p>
						<Switcher checked={pollWithPics.value} onChange={() => changeWPicture()} />
					</div>
				</div>
			</div>
			<Button className='btn btn--poll' onClick={nextStep}>
				{pollTranslate.btns.addQuestion}
				<IcoNext className='btn__right-icon' />
			</Button>
		</div>
	);
};

export default observer(PollSettings);
