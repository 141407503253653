import {ActionType} from 'models/enums/ActionType.enum';
import roomServices from 'store/roomServices';
import actionMessageServices from 'store/actionMessageServices';
import useL10n from 'l10n/useL10n';
import {encryptionUserName} from 'utils/encryptionUserName';
import useMessage from 'hooks/useMessage';
import {useEffect} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {CSSTransition} from 'react-transition-group';
import {ReactComponent as IconReplayYellow} from 'assets/svg/ico-replay-yellow.svg';
import {ReactComponent as IconEditYellow} from 'assets/svg/ico-edit-yellow.svg';
import {ReactComponent as IconClose} from 'assets/svg/ico-close.svg';
import Button from 'components/hoc/Button';
import './actionMessage.scss';

const ActionMessage: React.FC = function ActionMessage() {
	const {myTalker, toggleClearChatTextarea} = useLocalObservable(() => roomServices);
	const {isVisibleActionMessage, toggleIsVisibleActionMessage, actionData} = useLocalObservable(
		() => actionMessageServices
	);
	const {chatScreen} = useL10n();
	const {getMessageText, getBetTitle} = useMessage();

	const clearActionMessageAndClearChatTextarea = () => {
		toggleClearChatTextarea(true);
		toggleIsVisibleActionMessage(false, true);
	};

	const onCloseHandler = () => {
		const chatTextarea: HTMLTextAreaElement | null = document.querySelector('.chat__textarea-item');
		clearActionMessageAndClearChatTextarea();
		chatTextarea?.focus();
	};

	useEffect(() => {
		if (myTalker?.isBanned && actionData) {
			clearActionMessageAndClearChatTextarea();
		}
	}, [myTalker?.isBanned]);

	if (!actionData) {
		return null;
	}

	const {type, message} = actionData;
	const {talker} = message;
	const isEditType = type === ActionType.EDIT;

	return (
		<CSSTransition in={isVisibleActionMessage} timeout={500} classNames='fade' unmountOnExit>
			<div className='action-message'>
				<div className='action-message__icon'>
					{isEditType ? <IconEditYellow /> : <IconReplayYellow />}
				</div>
				<div className='action-message__content'>
					<div className='action-message__title'>
						{isEditType
							? chatScreen.actionMessage.editing
							: `${chatScreen.actionMessage.reply} ${
									talker.user.name ? encryptionUserName(talker.user.name) : 'User deleted'
							  }`}
					</div>
					{getMessageText(message) && (
						<div className='action-message__text'>{getMessageText(message)}</div>
					)}
					{message.sticker && <div className='action-message__text'>стикер</div>}
					{message.bet && <div className='action-message__text'>{getBetTitle(message)}</div>}
				</div>
				<Button className='action-message__close' onClick={onCloseHandler}>
					<IconClose />
				</Button>
			</div>
		</CSSTransition>
	);
};

export default observer(ActionMessage);
