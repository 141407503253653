import clappingHands from 'assets/images/emotions/clapping_hands.png';
import clappingHandsStatic from 'assets/images/emotions/clapping_hands_static.png';
import clappingHandsGif from 'assets/images/emotions/clapping_hands_short.gif';

import faceWithHeartEyes from 'assets/images/emotions/face_with_heart_eyes.png';
import faceWithHeartEyesStatic from 'assets/images/emotions/face_with_heart_eyes_static.png';
import faceWithHeartEyesGif from 'assets/images/emotions/face_with_heart_eyes_short.gif';

import flexedBiceps from 'assets/images/emotions/flexed_biceps.png';
import flexedBicepsStatic from 'assets/images/emotions/flexed_biceps_static.png';
import flexedBicepsGif from 'assets/images/emotions/flexed_biceps_short.gif';

import grinningFace from 'assets/images/emotions/grinning_face.png';
import grinningFaceStatic from 'assets/images/emotions/grinning_face_static.png';
import grinningFaceGif from 'assets/images/emotions/grinning_face_short.gif';

// import hushedFace from 'assets/images/emotions/hushed_face.png';
// import hushedFaceGif from 'assets/images/emotions/hushed_face_short.gif';

// import partyPopper from 'assets/images/emotions/party_popper.png';
// import partyPopperGif from 'assets/images/emotions/party_popper.png';

import poutingFace from 'assets/images/emotions/pouting_face.png';
import poutingFaceStatic from 'assets/images/emotions/pouting_face_static.png';
import poutingFaceGif from 'assets/images/emotions/pouting_face_short.gif';

import thumbsUp from 'assets/images/emotions/thumbs_up.png';
import thumbsUpStatic from 'assets/images/emotions/thumbs_up_static.png';
import thumbsUpGif from 'assets/images/emotions/thumbs_up_short.gif';

import yawningFace from 'assets/images/emotions/yawning_face.png';
import yawningFaceStatic from 'assets/images/emotions/yawning_face_static.png';
import yawningFaceGif from 'assets/images/emotions/yawning_face_short.gif';

import faceWithSteamFromNose from 'assets/images/emotions/face_with_steam_from_nose.png';
import faceWithSteamFromNoseStatic from 'assets/images/emotions/face_with_steam_from_nose_static.png';
import faceWithSteamFromNoseGif from 'assets/images/emotions/face_with_steam_from_nose_short.gif';

import fire from 'assets/images/emotions/fire.png';
import fireStatic from 'assets/images/emotions/fire_static.png';
import fireGif from 'assets/images/emotions/fire_short.gif';

import soccerBall from 'assets/images/emotions/soccer_ball.png';
import soccerBallStatic from 'assets/images/emotions/soccer_ball_static.png';
import soccerBallGif from 'assets/images/emotions/soccer_ball_short.gif';

import thumbsDown from 'assets/images/emotions/thumbs_down.png';
import thumbsDownStatic from 'assets/images/emotions/thumbs_down_static.png';
import thumbsDownGif from 'assets/images/emotions/thumbs_down_short.gif';

export const emotions = [
	{
		id: '10',
		src: fire,
		srcStatic: fireStatic,
		srcGif: fireGif,
		name: 'fire',
		eventType: 'fire',
		render: true,
	},
	{
		id: '3',
		src: flexedBiceps,
		srcStatic: flexedBicepsStatic,
		srcGif: flexedBicepsGif,
		name: 'flexedBiceps',
		eventType: 'flexed_biceps',
		render: true,
	},
	{
		id: '11',
		src: soccerBall,
		srcStatic: soccerBallStatic,
		srcGif: soccerBallGif,
		name: 'soccerBall',
		eventType: 'soccer_ball',
		render: true,
	},
	{
		id: '8',
		src: thumbsUp,
		srcStatic: thumbsUpStatic,
		srcGif: thumbsUpGif,
		name: 'thumbsUp',
		eventType: 'thumbs_up',
		render: true,
	},
	{
		id: '12',
		src: thumbsDown,
		srcStatic: thumbsDownStatic,
		srcGif: thumbsDownGif,
		name: 'thumbsDown',
		eventType: 'thumbs_down',
		render: true,
	},
	{
		id: '1',
		src: clappingHands,
		srcStatic: clappingHandsStatic,
		srcGif: clappingHandsGif,
		name: 'clappingHands',
		eventType: 'clapping_hands',
		render: true,
	},
	{
		id: '13',
		src: faceWithSteamFromNose,
		srcStatic: faceWithSteamFromNoseStatic,
		srcGif: faceWithSteamFromNoseGif,
		name: 'faceWithSteamFromNose',
		eventType: 'face_with_steam_from_nose',
		render: true,
	},

	{
		id: '2',
		src: faceWithHeartEyes,
		srcStatic: faceWithHeartEyesStatic,
		srcGif: faceWithHeartEyesGif,
		name: 'faceWithHeartEyes',
		eventType: 'face_with_heart_eyes',
		render: false,
	},
	{
		id: '4',
		src: grinningFace,
		srcStatic: grinningFaceStatic,
		srcGif: grinningFaceGif,
		name: 'grinningFace',
		eventType: 'grinning_face',
		render: false,
	},
	{
		id: '9',
		src: yawningFace,
		srcStatic: yawningFaceStatic,
		srcGif: yawningFaceGif,
		name: 'yawningFace',
		eventType: 'yawning_face',
		render: false,
	},
	{
		id: '7',
		src: poutingFace,
		srcStatic: poutingFaceStatic,
		srcGif: poutingFaceGif,
		name: 'poutingFace',
		eventType: 'pouting_face',
		render: false,
	},
	// {
	// 	id: '5',
	// 	src: hushedFace,
	// 	srcGif: hushedFaceGif,
	// 	name: 'hushedFace',
	// 	eventType: 'hushed_face',
	// },
	// {
	// 	id: '6',
	// 	src: partyPopper,
	// 	srcGif: partyPopperGif,
	// 	name: 'partyPopper',
	// 	eventType: 'party_popper',
	// },
];
