import {USER_UNNAMED} from 'constants/constants';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import userServices from 'store/userServices';
import appService from 'store/appService';
import RoomService from 'services/api/RoomService';
import {useCallback, useEffect, useState} from 'react';
import {encryptionUserName} from 'utils/encryptionUserName';
import {observer, useLocalObservable} from 'mobx-react-lite';
import AnonimAva from 'assets/images/anonim-ava.jpg';
import './blockedUsers.scss';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import Button from 'components/hoc/Button';
import {Avatar} from 'components/avatar';

const BlockedUsers: React.FC = function BlockedUsers() {
	const [localBlockedUsers, setLocalBlockedUsers] = useState<User[]>([]);
	const {accessToken} = useLocalObservable(() => userServices);
	const {blockedUsers, addBlockedUser} = useLocalObservable(() => appService);
	const {btns} = useL10n();
	const {sendAnalytics} = useAnalytics();

	const checkBlockedUser = (blockedUser: User) =>
		blockedUsers.find(item => item.id === blockedUser.id);

	const onToggleBLockHandler = async (blockedUser: User) => {
		if (blockedUser.id && accessToken) {
			if (checkBlockedUser(blockedUser)) {
				sendAnalytics('unblocking_pressed');
				await RoomService.deleteBlockedUser(blockedUser.id, accessToken);
				return;
			}

			const response = await RoomService.blockUser(blockedUser.id, accessToken);
			if (response.status === ResponseStatus.SUCCESS) {
				addBlockedUser(blockedUser);
			}
		}
	};

	const renderBlockedUser = useCallback(
		(blockedUser: User) => (
			<div
				className={`blocked-users__item ${
					!checkBlockedUser(blockedUser) && 'blocked-users__item--accent'
				}`}
				key={blockedUser.id}>
				<div className='blocked-users__item-avatar'>
					<Avatar
						src={blockedUser.pic !== '' ? blockedUser.pic : AnonimAva}
						alt={blockedUser.name && blockedUser.name !== '' ? blockedUser.name : USER_UNNAMED}
						className='blocked-users__item-avatar-img'
					/>
				</div>
				<div className='blocked-users__item-right'>
					<p className='blocked-users__item-name'>
						{typeof blockedUser.name !== 'undefined' && blockedUser.name !== ''
							? encryptionUserName(blockedUser.name)
							: USER_UNNAMED}
					</p>
					<Button
						className='blocked-users__item-btn'
						onClick={() => onToggleBLockHandler(blockedUser)}>
						{checkBlockedUser(blockedUser) ? btns.unblock : btns.block}
					</Button>
				</div>
			</div>
		),
		[localBlockedUsers, blockedUsers]
	);

	useEffect(() => {
		if (!localBlockedUsers.length) {
			setLocalBlockedUsers(blockedUsers);
		}
	}, [blockedUsers]);

	return <div className='blocked-users'>{localBlockedUsers.map(renderBlockedUser)}</div>;
};

export default observer(BlockedUsers);
