/* eslint-disable max-lines */
import {Message, Talker} from 'models/room';
import {NUMBER_OF_MESSAGES_TO_DOWNLOAD, TALKER_STUB} from 'constants/constants';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import MessageType from 'models/enums/MessageType.enum';
import useAdvertisement from 'hooks/useAdvertisement';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import pollServices from 'store/pollServices';
import RoomService from 'services/api/RoomService';
import {useEffect, useRef} from 'react';
import {useLocalObservable} from 'mobx-react-lite';

const BUTTON_VISIBILITY_BORDER = 35;

const getVerifiedMessages = (messages: Message[]) => {
	return messages.map(m => {
		if (m.talker) {
			return {...m, isNotReaded: false};
		}
		return {...m, talker: TALKER_STUB, isNotReaded: false};
	});
};

export default () => {
	const myTalkerRef = useRef<Talker | null>();
	const blockedUsersForFilteredMessagesRef = useRef<number[]>([]);
	const {accessToken} = useLocalObservable(() => userServices);
	const {
		roomId,
		myTalker,
		messages,
		mentionMessageArray,
		reduceMentionMessageArray,
		updateMessage,
		unshiftMessages,
		pushMessages,
		addMessages,
		updatePinnedMessagePinStatus,
		toggleRestApiDataLoaded,
		chatScrollPositionBottom,
		previousMessagesMode,
		toggleChatScrollPositionBottom,
		togglePreviousMessagesMode,
		unrecordedMessages,
		setUnrecordedMessage,
		blockedUsersForFilteredMessages,
	} = useLocalObservable(() => roomServices);
	const {setPoll, setPollOptionsChosen, toggllePollAlreadyVoted} = useLocalObservable(
		() => pollServices
	);
	const {checkViewedAdvertisement} = useAdvertisement();

	useEffect(() => {
		if (myTalker) {
			myTalkerRef.current = myTalker;
		}
	}, [myTalker]);

	useEffect(() => {
		blockedUsersForFilteredMessagesRef.current = blockedUsersForFilteredMessages;
	}, [blockedUsersForFilteredMessages]);

	const checkMessages = (data: Message[]) => {
		return data.filter(
			message =>
				!blockedUsersForFilteredMessagesRef.current.includes(message.talker.user?.id as number) &&
				(message.type === MessageType.USER ||
					message.type === MessageType.ADVERTISEMENT ||
					message.type === MessageType.POLLRESULTS ||
					message.type === MessageType.STICKER ||
					(message.type === MessageType.VOTE &&
						message.talker.user?.id === myTalkerRef.current?.user.id))
		);
	};

	const getMessagesWithServices = async (limit?: number | undefined) => {
		if (accessToken && roomId) {
			const response = await RoomService.getDataOnLoad(accessToken, roomId, limit);
			if (response.status === ResponseStatus.SUCCESS) {
				let pinnedMessageData;
				const verifiedMessages = getVerifiedMessages(response.data.messages);
				if (response.data.pinnedMessage) {
					if (response.data.pinnedMessage.talker) {
						pinnedMessageData = response.data.pinnedMessage;
					} else {
						pinnedMessageData = {...response.data.pinnedMessage, talker: TALKER_STUB};
					}
					updatePinnedMessagePinStatus(pinnedMessageData);
				}
				addMessages(verifiedMessages);

				toggleRestApiDataLoaded({messagesLoaded: true, pinnedMessageLoaded: true});
				return;
			}
			toggleRestApiDataLoaded({messagesLoaded: false, pinnedMessageLoaded: false});
		}
	};

	const getPollWithServices = async () => {
		if (accessToken && roomId) {
			const response = await RoomService.getPoll(accessToken, roomId);

			if (response?.status === ResponseStatus.SUCCESS) {
				const {poll, alreadyVoted, chosenOptions} = response.data;
				if (poll) {
					setPoll(poll, roomId);
				}
				if (chosenOptions?.length) {
					setPollOptionsChosen(chosenOptions);
				}
				toggllePollAlreadyVoted(alreadyVoted);
				toggleRestApiDataLoaded({pollLoaded: true});
				return;
			}
			toggleRestApiDataLoaded({pollLoaded: false});
		}
	};

	const scrollToMessage = (
		messageID: number,
		chatMessagesRef: HTMLDivElement | null,
		chatScrollRef: HTMLDivElement | null,
		callback?: boolean,
		offset?: boolean
	) => {
		let messageLastChildPaddingBottom = 0;
		let scrollTimeout: any;
		let calcHeightForCenterScroll = 0;

		const messageToScroll = document.querySelector(`.chat__message[data-id="${messageID}"]`);
		const messageLastChild = document.querySelector('.chat__message:last-child');
		const pinnedMessageHeight = document.querySelector('.pinned-message')?.clientHeight;
		const headerHeight = document.querySelector('.header')?.clientHeight;
		const bottomHeight = document.querySelector('.main-bottom')?.clientHeight;

		if (pinnedMessageHeight) {
			calcHeightForCenterScroll += pinnedMessageHeight;
		}
		if (headerHeight) {
			calcHeightForCenterScroll += headerHeight;
		}
		if (bottomHeight) {
			calcHeightForCenterScroll -= bottomHeight;
		}

		if (messageLastChild && getComputedStyle(messageLastChild)) {
			messageLastChildPaddingBottom = parseInt(
				getComputedStyle(messageLastChild).paddingBottom,
				10
			);
		}

		if (chatMessagesRef && messageToScroll && messageToScroll instanceof HTMLElement) {
			let calcScrollOffsetY =
				messageLastChildPaddingBottom +
				messageToScroll.offsetTop +
				messageToScroll.clientHeight -
				chatMessagesRef.clientHeight;

			if (offset && chatScrollRef) {
				calcScrollOffsetY =
					messageToScroll.offsetTop -
					chatScrollRef.clientHeight / 2 +
					messageToScroll.offsetHeight / 2 -
					calcHeightForCenterScroll / 2;
			}

			chatScrollRef?.scroll({
				top: calcScrollOffsetY,
				behavior: 'smooth',
			});

			if (callback) {
				document
					.querySelector(`.chat__message[data-id="${messageID}"] .chat__message-container`)
					?.classList.add('chat__message-container--lighted');
				chatScrollRef?.addEventListener('scroll', () => {
					clearTimeout(scrollTimeout);
					scrollTimeout = setTimeout(() => {
						document
							.querySelector(`.chat__message[data-id="${messageID}"] .chat__message-container`)
							?.classList.remove('chat__message-container--lighted');
					}, 1000);
				});

				/* trigger scroll start */
				const event = new Event('scroll');
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				chatScrollRef?.addEventListener('scroll', () => {}, false);
				chatScrollRef?.dispatchEvent(event);
				/* trigger scroll end */
			}
		}
	};

	const getMessagesOnScroll = async (
		messageCount: number,
		messageFirstId?: number,
		messageLastId?: number
	) => {
		if (accessToken && roomId) {
			const response = await RoomService.getMessages(
				accessToken,
				roomId,
				messageCount,
				messageFirstId,
				messageLastId
			);

			if (response.status === ResponseStatus.SUCCESS) {
				return response.data;
			}
		}
		return [];
	};

	const getMessagesByIdAndCount = async (
		setDisableScroll: (val: boolean) => void,
		setVisiblePreloader: (val: boolean) => void,
		messageId: number,
		messageCount: number,
		forward = false
	) => {
		setVisiblePreloader(true);
		setDisableScroll(true);

		if (roomId) {
			if (forward) {
				const responseMessages = await getMessagesOnScroll(messageCount, messageId);

				if (responseMessages.length) {
					const verifiedMessages = getVerifiedMessages(responseMessages);

					if (checkMessages(verifiedMessages).length) {
						pushMessages(verifiedMessages);

						if (unrecordedMessages.length) {
							const findMessagesIdArr: number[] = [];
							verifiedMessages.forEach(vm => {
								if (unrecordedMessages.find(fm => fm.id === vm.id)) {
									findMessagesIdArr.push(vm.id);
								}
							});

							if (findMessagesIdArr.length) {
								const filterUnrecordedMessages = unrecordedMessages.filter(
									item => !findMessagesIdArr.includes(item.id)
								);
								setUnrecordedMessage(filterUnrecordedMessages);
							}
						}
					} else {
						toggleChatScrollPositionBottom(true);
						togglePreviousMessagesMode(false);
					}
				} else {
					toggleChatScrollPositionBottom(true);
					togglePreviousMessagesMode(false);
				}
				setVisiblePreloader(false);
				setDisableScroll(false);
				return;
			}

			if (!forward) {
				const responseMessages = await getMessagesOnScroll(messageCount, undefined, messageId);

				const addMessagesToStorage = (incomingMessages: Message[]) => {
					toggleChatScrollPositionBottom(false);
					unshiftMessages(incomingMessages);
					setVisiblePreloader(false);
					setDisableScroll(false);
				};

				if (responseMessages.length) {
					const verifiedMessages = getVerifiedMessages(responseMessages);

					if (checkMessages(verifiedMessages).length) {
						addMessagesToStorage(verifiedMessages);
						return;
					}

					if (verifiedMessages.length) {
						const responseMessagesNextRequest = await getMessagesOnScroll(
							messageCount,
							undefined,
							verifiedMessages[0].id
						);

						if (responseMessagesNextRequest.length) {
							const verifiedMessagesNextRequest = getVerifiedMessages(responseMessagesNextRequest);

							if (checkMessages(verifiedMessagesNextRequest).length) {
								addMessagesToStorage(verifiedMessagesNextRequest);
								return;
							}
						}
					}
				}
			}
		}

		setVisiblePreloader(false);
		setDisableScroll(false);
	};

	const getMessagesAround = async (
		chatMessagesRef: HTMLDivElement | null,
		setDisableScroll: (val: boolean) => void,
		setVisiblePreloader: (val: boolean) => void,
		messageId: number,
		limit: number
	) => {
		setVisiblePreloader(true);
		setDisableScroll(true);
		if (roomId) {
			const response = await RoomService.getMessagesAround(accessToken, roomId, messageId, limit);
			if (response.status === ResponseStatus.SUCCESS && response.data.length) {
				const verifiedMessages = getVerifiedMessages(response.data);
				setVisiblePreloader(false);
				setDisableScroll(false);
				chatMessagesRef?.scroll({top: 10});
				addMessages(verifiedMessages);
				return {status: ResponseStatus.SUCCESS};
			}
		}
		setVisiblePreloader(false);
		setDisableScroll(false);
		return {status: ResponseStatus.ERROR};
	};

	const checkScrollTop = async (
		setDisableScroll: (val: boolean) => void,
		setVisiblePreloader: (val: boolean) => void,
		setNewMessagesCount: (val: number) => void,
		setScrollToBottom: (val: boolean) => void,
		resetStateAfterScrollToBottom: () => void,
		newMessagesCount: number,
		visiblePreloader: boolean,
		scrollToBottom: boolean,
		chatMessagesRef: HTMLDivElement | null,
		event: React.BaseSyntheticEvent
	) => {
		const {clientHeight, scrollTop, scrollHeight} = event.target;

		if (previousMessagesMode) {
			// подгрузка сообщений в верхней точке скролла
			if (chatMessagesRef && chatMessagesRef.offsetHeight - clientHeight === Math.abs(scrollTop)) {
				const {0: messageFirst} = messages;

				if (!visiblePreloader && accessToken && messageFirst) {
					await getMessagesByIdAndCount(
						setDisableScroll,
						setVisiblePreloader,
						messageFirst.id,
						NUMBER_OF_MESSAGES_TO_DOWNLOAD
					);
				}
			}

			// подгрузка сообщений в нижней точке скролла
			if (chatMessagesRef && chatMessagesRef.offsetHeight + scrollTop === Math.abs(scrollHeight)) {
				const messageLast = messages[messages.length - 1];

				if (!visiblePreloader && accessToken && messageLast) {
					await getMessagesByIdAndCount(
						setDisableScroll,
						setVisiblePreloader,
						messageLast.id,
						NUMBER_OF_MESSAGES_TO_DOWNLOAD,
						true
					);
				}
			}
			return;
		}

		const filteredNotViewedMessages = messages.filter(
			item => item.type !== MessageType.VOTE && item.isVisible && item.isNotReaded
		);
		const advertisementMessages = messages.filter(item => item.type === MessageType.ADVERTISEMENT);
		let countViewedMessages = 0;

		// подгрузка сообщений в верхней точке скролла
		if (chatMessagesRef && chatMessagesRef.offsetHeight - clientHeight === Math.abs(scrollTop)) {
			const {0: messageFirst} = messages;

			if (!visiblePreloader && accessToken && messageFirst) {
				await getMessagesByIdAndCount(
					setDisableScroll,
					setVisiblePreloader,
					messageFirst.id,
					NUMBER_OF_MESSAGES_TO_DOWNLOAD
				);
			}
		}

		filteredNotViewedMessages.forEach(el => {
			const selectedMessage = document.querySelector(`.chat__message[data-id="${el.id}"]`);

			if (selectedMessage && selectedMessage instanceof HTMLElement) {
				const {offsetTop, offsetHeight} = selectedMessage;
				const messageBorderBottom = Math.floor(offsetTop + offsetHeight); // нижняя граница сообщения
				const scrollBorderBottom = Math.ceil(scrollTop + clientHeight); // нижняя граница скрола

				if (messageBorderBottom <= scrollBorderBottom) {
					updateMessage({...el, isNotReaded: false});

					if (newMessagesCount) {
						countViewedMessages += 1;
						if (!scrollToBottom) {
							setScrollToBottom(true);
						}
					}

					if (el.mentionMessage && mentionMessageArray.length !== 0) {
						reduceMentionMessageArray();
					}
				}
			}
		});

		if (filteredNotViewedMessages.length && newMessagesCount) {
			setNewMessagesCount(filteredNotViewedMessages.length - countViewedMessages);
		}

		if (advertisementMessages.length) {
			checkViewedAdvertisement(advertisementMessages, clientHeight);
		}

		if (scrollHeight - (scrollTop + clientHeight) >= BUTTON_VISIBILITY_BORDER) {
			if (chatScrollPositionBottom) {
				toggleChatScrollPositionBottom(false);
			}
		} else {
			resetStateAfterScrollToBottom();
			window.parent.postMessage({type: 'allMessagesRead'}, '*');
		}
	};

	return {
		getMessagesWithServices,
		getMessagesByIdAndCount,
		getMessagesAround,
		scrollToMessage,
		checkScrollTop,
		getPollWithServices,
	};
};
