import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import axios from 'axios';
import {apiPath} from 'constants/api';
import {ResponseUserData} from 'models/responses';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import Log from 'utils/log';
import checkResponse from 'utils/checkResponse';

export default class UserService {
	static patchUserData = async (value: Record<string, string | number | boolean>, token: UUID) => {
		try {
			const {data, status} = await axios.patch(apiPath.patchMyUser, value, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			Log.error('Patch user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static selfDeleteUser = async (token: UUID) => {
		try {
			const {data, status} = await axios.delete<ResponseUserData>(apiPath.deleteMyUser, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			Log.error('Delete user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static restoreMyUser = async (token: UUID) => {
		try {
			const {data, status} = await axios.get(apiPath.restoreMyUser, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			Log.error('Restore user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static setSelfModer = async (externalRoomId: string, talkerId: number, value: boolean) => {
		try {
			const params = {
				externalRoomId,
				talkerId,
				value,
			};
			const {data, status} = await axios.post(apiPath.selfmoder, params);

			checkResponse({data}, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Check user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}
