import {emotions} from 'data/emotions';
import {GenerateReaction} from 'models/room';
import appService from 'store/appService';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import useReactions from 'hooks/useReactions';
import {FunctionComponent, useCallback} from 'react';
import './reactions.scss';
import {observer, useLocalObservable} from 'mobx-react-lite';

interface IReactionsProps {
	reactions: GenerateReaction[];
}

const Reactions: FunctionComponent<IReactionsProps> = function Reactions({reactions}) {
	const {appReadOnly} = useLocalObservable(() => appService);
	const {userData, isUserExternalIdCorrect} = useLocalObservable(() => userServices);
	const {myTalker} = useLocalObservable(() => roomServices);
	const {reactionItemHandler} = useReactions();

	const onClickReactionItemHandler = (item: GenerateReaction) => {
		if (!appReadOnly && isUserExternalIdCorrect) {
			reactionItemHandler(item);
		}
	};

	const renderReactionItem = useCallback(
		(item: GenerateReaction) => {
			const findReactionData = emotions.find(e => e.name === item.name);
			if (findReactionData) {
				return (
					<div className='reactions__item' key={item.id}>
						<button
							type='button'
							className={`reactions__item-btn ${
								item.items.find(r => r.emotion === item.name && r.talkerId === myTalker?.id)
									? 'reactions__item-btn--selected'
									: ''
							}`}
							onClick={() => onClickReactionItemHandler(item)}>
							<img src={findReactionData.srcStatic} alt='' className='reactions__item-img' />
							{item.items.length}
						</button>
					</div>
				);
			}
			return null;
		},
		[reactions, myTalker, userData]
	);

	return (
		<div className='reactions'>
			<div className='reactions__items'>{reactions.map(renderReactionItem)}</div>
		</div>
	);
};

export default observer(Reactions);
