/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {useDrag, useDrop} from 'react-dnd';
import {PollType} from 'models/enums/Poll.enum';
import type {Identifier, XYCoord} from 'dnd-core';
import userServices from 'store/userServices';
import pollService from 'store/pollServices';
import toastService from 'store/toastService';
import RoomService from 'services/api/RoomService';
import classNames from 'classnames';
import {isIOS} from 'react-device-detect';
import {ToastIconName} from 'components/toasts/ToastIcons';
import _ from 'lodash';
import useL10n from 'l10n/useL10n';
import {Field} from 'components/hoc';

import './pollAnswers.scss';

interface IPollAnswerElementProps {
	index: number;
	id: string | number;
	text: string;
	img: string | null;
	isRight: boolean;
	moveCard: (dragIndex: number, hoverIndex: number) => void;
	changeData: (id: string, data: any, isRadio?: boolean) => void;
	removeElementOnBlur: (e: React.FocusEvent<HTMLInputElement>, id: string) => void;
}

interface IDragItem {
	index: number;
	id: string;
	type: string;
}

const TEXT_MAX_LENGTH = 25;
const TEXT_MAX_LENGTH_WITH_IMG = 75;

const PollAnswerElement: FunctionComponent<IPollAnswerElementProps> = function PollAnswerElement(
	props
) {
	const {index, id, text, img, isRight, moveCard, changeData, removeElementOnBlur} = props;
	const {toasts, poll: pollTranslate} = useL10n();
	const {pollCreated, pollWithPics} = useLocalObservable(() => pollService);
	const {accessToken} = useLocalObservable(() => userServices);
	const {addToast} = useLocalObservable(() => toastService);
	const [textLengthVisible, setTextLengthVisible] = useState(false);
	const [tipVisibility, setTipVisibility] = useState(true);
	const ref = useRef<HTMLDivElement>(null);
	const tetxInputRef = useRef<HTMLInputElement>(null);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const uniqId = `${id}`;
	const isPollWithPics = img && img !== '' && pollWithPics.value;
	const answerMaxLength = pollWithPics.value ? TEXT_MAX_LENGTH : TEXT_MAX_LENGTH_WITH_IMG;

	const [{handlerId}, drop] = useDrop<IDragItem, void, {handlerId: Identifier | null}>({
		accept: 'CARD',
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item: IDragItem, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			if (dragIndex === hoverIndex) {
				return;
			}

			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const clientOffset = monitor.getClientOffset();
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			moveCard(dragIndex, hoverIndex);
			// eslint-disable-next-line no-param-reassign
			item.index = hoverIndex;
		},
	});

	const [{isDragging}, drag] = useDrag({
		type: 'CARD',
		item: () => {
			return {id, index};
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const opacity = isDragging ? 0 : 1;
	drag(drop(ref));

	const checkImageSize = (url: any) => {
		const newImg = new Image();

		const promise = new Promise((resolve, reject) => {
			newImg.onload = () => {
				const width = newImg.naturalWidth;
				const height = newImg.naturalHeight;
				resolve({width, height});
			};

			newImg.onerror = reject;
		});

		newImg.src = window.URL.createObjectURL(url);

		return promise;
	};

	const uploadPic = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			checkImageSize(e.target.files[0]).then((object: any) => {
				(async () => {
					if (object.width > 250 && object.height > 250 && e.target.files) {
						if (e.target.files[0].size < 10485760) {
							const formData = new FormData();
							formData.append('pic', e.target.files[0], e.target.files[0].name);
							const response = await RoomService.uploadPollOptionPicture(formData, accessToken);
							if (response) {
								changeData(uniqId, {pic: response.data.pic});
							}
							return;
						}
					}
					if (fileInputRef.current) {
						fileInputRef.current.value = '';
					}
					addToast({
						iconName: ToastIconName.brickRed,
						liveTime: 3000,
						message: toasts.pollAddPicError,
						cancellable: true,
					});
				})();
			});
		}
	};

	const onChangeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = e.target;
		changeData(uniqId, {text: value});
	};

	const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
		removeElementOnBlur(e, uniqId);
		setTextLengthVisible(false);
	};

	const onChangeCheckboxHandler = () => {
		setTipVisibility(false);
		if (!pollCreated?.isMultiple) {
			changeData(uniqId, {isRight: !isRight}, true);
			return;
		}
		changeData(uniqId, {isRight: !isRight});
	};

	const pollAnswersInputStyles = classNames('poll-answers__input-length', {
		'poll-answers__input-length--out': text.length > 0,
	});

	useEffect(() => {
		const timeout = setTimeout(() => {
			setTipVisibility(false);
		}, 3000);

		if (tetxInputRef.current) {
			tetxInputRef.current.focus();
		}

		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, []);

	return (
		<div className='poll-answers__element' style={{opacity}} ref={ref}>
			{pollCreated?.type === PollType.QUIZ && (
				<div className='poll-answers__radio'>
					{index === 0 && tipVisibility && (
						<span className='poll-answers__radio-tip'>{pollTranslate.answer.tooltip}</span>
					)}
					<input
						type='checkbox'
						id={`answer-${uniqId}`}
						checked={isRight}
						className='poll-answers__radio-input'
						onChange={onChangeCheckboxHandler}
					/>
					<label htmlFor={`answer-${uniqId}`} className='poll-answers__radio-label' />
				</div>
			)}

			<div className='poll-answers__element-wrapper'>
				{isPollWithPics && <img src={img} alt='answerPic' className='poll-answers__img-preview' />}
				<Field
					fieldType='input'
					type='text'
					value={text}
					className='poll-answers__input'
					placeholder={pollTranslate.answer.inputPlaceholder}
					maxLength={answerMaxLength}
					onChange={onChangeInputHandler}
					onBlur={onBlurHandler}
					onFocus={() => setTextLengthVisible(true)}
					innerRef={tetxInputRef}
				/>
				{textLengthVisible && (
					<span className={pollAnswersInputStyles}>
						{!isIOS ? answerMaxLength - text.length : answerMaxLength - _.toArray(text).length}
					</span>
				)}
				<div className='poll-answers__btns'>
					{pollWithPics.value && (
						<label htmlFor={uniqId} className='poll-answers__file'>
							<input
								ref={fileInputRef}
								id={uniqId}
								type='file'
								accept='.jpg, .jpeg, .png'
								className='poll-answers__file-input'
								onChange={uploadPic}
							/>
						</label>
					)}
					<span className='poll-answers__dragger' />
				</div>
			</div>
		</div>
	);
};

export default observer(PollAnswerElement);
