import Img1 from 'assets/images/nickname/Img-1.svg';
import Img2 from 'assets/images/nickname/Img-2.svg';
import Img3 from 'assets/images/nickname/Img-3.svg';
import Img4 from 'assets/images/nickname/Img-4.svg';
import Img5 from 'assets/images/nickname/Img-5.svg';
import Img6 from 'assets/images/nickname/Img-6.svg';
import Img7 from 'assets/images/nickname/Img-7.svg';
import Img8 from 'assets/images/nickname/Img-8.svg';
import Img9 from 'assets/images/nickname/Img-9.svg';
import Img10 from 'assets/images/nickname/Img-10.svg';
import Img11 from 'assets/images/nickname/Img-11.svg';
import Img12 from 'assets/images/nickname/Img-12.svg';

export const avatars = [
	{
		id: 0,
		src: Img1,
		name: 'DEFAULT_1',
	},
	{
		id: 1,
		src: Img2,
		name: 'DEFAULT_2',
	},
	{
		id: 2,
		src: Img3,
		name: 'DEFAULT_3',
	},
	{
		id: 3,
		src: Img4,
		name: 'DEFAULT_4',
	},
	{
		id: 4,
		src: Img5,
		name: 'DEFAULT_5',
	},
	{
		id: 5,
		src: Img6,
		name: 'DEFAULT_6',
	},
	{
		id: 6,
		src: Img7,
		name: 'DEFAULT_7',
	},
	{
		id: 7,
		src: Img8,
		name: 'DEFAULT_8',
	},
	{
		id: 8,
		src: Img9,
		name: 'DEFAULT_9',
	},
	{
		id: 9,
		src: Img10,
		name: 'DEFAULT_10',
	},
	{
		id: 10,
		src: Img11,
		name: 'DEFAULT_11',
	},
	{
		id: 11,
		src: Img12,
		name: 'DEFAULT_12',
	},
];
