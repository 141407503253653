/* eslint-disable max-lines */
import {MAXIMUM_NUMBER_OF_MESSAGES, START_NUMBER_OF_MESSAGES} from 'constants/constants';
import {getParent, containsClass, qs} from 'utils/helpers';
import MessageType from 'models/enums/MessageType.enum';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {Message, Talker} from 'models/room';
import {PollMessageData} from 'models/poll';
import appService from 'store/appService';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import controlPanelServices from 'store/controlPanelServices';
import stickersEmojiServices from 'store/stickersEmojiServices';
import slowModeServices from 'store/slowModeServices';
import RoomService from 'services/api/RoomService';
import {useRef, FunctionComponent, useCallback, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {isDesktop} from 'react-device-detect';
import {PollMessage} from 'components/poll/pollMessage';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import useChatActions from 'hooks/useChatActions';
import useMessage from 'hooks/useMessage';
import Button from 'components/hoc/Button';

import ChatMessagesPreloader from './ChatMessagesPreloader';
import ChatIntro from './ChatIntro';
import ChatMessage from './ChatMessage';
import {PinnedMessage} from '../pinnedMessage';
import './chat.scss';

const Chat: FunctionComponent = function Chat() {
	const chatScrollRef = useRef<HTMLDivElement>(null);
	const chatMessagesRef = useRef<HTMLDivElement>(null);
	const [localMessages, setLocalMessages] = useState<Message[]>([]);
	const [visiblePreloader, setVisiblePreloader] = useState(false);
	const [disableScroll, setDisableScroll] = useState(false);
	const [lastViewedMessage, setLastViewedMessage] = useState<number | null>(null);
	const [newMessagesCount, setNewMessagesCount] = useState<number>(0);
	const [scrollToBottom, setScrollToBottom] = useState<boolean>(false);
	const [checkUserLastMessageForSlowMode, setCheckUserLastMessageForSlowMode] =
		useState<boolean>(false);
	const {appReadOnly, isInternernetActive} = useLocalObservable(() => appService);
	const {userData, isUserExternalIdCorrect, accessToken} = useLocalObservable(() => userServices);
	const {
		roomId,
		myTalker,
		messages,
		unrecordedMessages,
		pinnedMessage,
		mentionMessageArray,
		removeMentionMessage,
		updateMentionMessageArray,
		submenuMessage,
		setSubmenuMessage,
		blockedUsersForFilteredMessages,
		chatScrollPositionBottom,
		previousMessagesMode,
		removeUnrecordedMessage,
		removeUnrecordedMessages,
		togglePreviousMessagesMode,
		toggleChatScrollPositionBottom,
	} = useLocalObservable(() => roomServices);
	const {visibleButtons, toggleVisibleButtons} = useLocalObservable(() => controlPanelServices);
	const {togglleVisibleStickersEmoji} = useLocalObservable(() => stickersEmojiServices);
	const {slowMode, setSlowModeTimeLeft, setslowModeTimeLeftOffset, toggleSlowModeCountdownEnable} =
		useLocalObservable(() => slowModeServices);
	const {sendAnalytics} = useAnalytics();
	const {getMessagesWithServices, getMessagesAround, scrollToMessage, checkScrollTop} =
		useChatActions();
	const {visibleMessageElements} = useMessage();

	const isMyTalkerModer = !!myTalker?.isModer;
	const talkerIsBanned = !!myTalker?.isBanned;

	const chatMessagesClasses = classNames('chat__messages', {
		'chat__messages--wpinned': pinnedMessage !== null,
	});

	const checkMessage = (message: Message) => {
		return (
			isMyTalkerModer ||
			(!isMyTalkerModer && message.talker.user?.id === userData?.id && !message.isVisible) ||
			(!isMyTalkerModer && message.isVisible)
		);
	};

	const visibleSubmenu = (messageId: number) => {
		if (submenuMessage && submenuMessage.messageId === messageId && !talkerIsBanned) {
			return true;
		}
		return false;
	};

	const onShowButtonsHandler = () => {
		if (!visibleButtons) {
			toggleVisibleButtons(true);
		}
	};

	const resetStateAfterScrollToBottom = () => {
		if (!chatScrollPositionBottom) {
			toggleChatScrollPositionBottom(true);
		}

		if (mentionMessageArray.length) {
			updateMentionMessageArray(null);
		}

		setLastViewedMessage(null);
		setNewMessagesCount(0);
		setScrollToBottom(false);
	};

	const onScrollToUnreadMessagesHandler = () => {
		sendAnalytics('scrolled_to_unread_messages');

		if (previousMessagesMode) {
			togglePreviousMessagesMode(false);
			removeUnrecordedMessages();
			getMessagesWithServices(START_NUMBER_OF_MESSAGES);
			resetStateAfterScrollToBottom();
			return;
		}

		if (newMessagesCount !== 0 && !scrollToBottom) {
			const messageToScroll = document.querySelector(
				`.chat__message[data-id="${lastViewedMessage}"]`
			);
			const nextMessageIdToScroll = (messageToScroll?.nextSibling as HTMLElement | null)?.dataset
				.id;
			if (
				lastViewedMessage &&
				nextMessageIdToScroll &&
				messageToScroll &&
				messageToScroll instanceof HTMLElement
			) {
				scrollToMessage(
					parseInt(nextMessageIdToScroll, 10),
					chatMessagesRef.current,
					chatScrollRef.current
				);
			}
			setScrollToBottom(true);
		}

		if (newMessagesCount === 0 || scrollToBottom) {
			chatScrollRef.current?.scroll({top: chatScrollRef.current?.scrollHeight});
			resetStateAfterScrollToBottom();
		}
	};

	const onClickMessageReplayHandler = async (event: any) => {
		const eventTarget = event.target;
		if (eventTarget && getParent(eventTarget, 'chat__message-replay')) {
			event.preventDefault();
			const messageReplayId = parseInt(
				getParent(eventTarget, 'chat__message-replay').dataset.replayId,
				10
			);
			if (messageReplayId) {
				if (messageReplayId && qs(`.chat__message[data-id="${messageReplayId}"]`)) {
					scrollToMessage(
						messageReplayId,
						chatMessagesRef.current,
						chatScrollRef.current,
						true,
						true
					);
					return;
				}

				const response = await getMessagesAround(
					chatScrollRef.current,
					setDisableScroll,
					setVisiblePreloader,
					messageReplayId,
					MAXIMUM_NUMBER_OF_MESSAGES
				);

				if (response.status === ResponseStatus.SUCCESS) {
					if (!previousMessagesMode) {
						togglePreviousMessagesMode(true);
					}

					setTimeout(() => {
						scrollToMessage(
							messageReplayId,
							chatMessagesRef.current,
							chatScrollRef.current,
							true,
							true
						);
					}, 500);
				}

				toggleChatScrollPositionBottom(false);
			}
		}
	};

	const onContextMenuHandler = (event: any) => {
		const eventTarget = event.target;
		if (
			eventTarget &&
			getParent(eventTarget, 'chat__message') &&
			!appReadOnly &&
			isUserExternalIdCorrect &&
			userData?.isOnboard &&
			!talkerIsBanned
		) {
			const messageId = parseInt(getParent(eventTarget, 'chat__message').dataset.id, 10);
			if (
				getParent(eventTarget, 'chat__message-body') ||
				containsClass(eventTarget, 'chat__message-body')
			) {
				event.preventDefault();
				setSubmenuMessage({
					messageId,
				});
				togglleVisibleStickersEmoji(false);
			}
		}
	};

	const onClickOnPinnedMessageHandler = async (messageId: number) => {
		if (
			pinnedMessage &&
			!blockedUsersForFilteredMessages.includes(pinnedMessage.talker.user?.id as number)
		) {
			if (localMessages.find(lm => lm.id === messageId)) {
				scrollToMessage(messageId, chatMessagesRef.current, chatScrollRef.current, true, true);
				return;
			}

			const response = await getMessagesAround(
				chatScrollRef.current,
				setDisableScroll,
				setVisiblePreloader,
				messageId,
				MAXIMUM_NUMBER_OF_MESSAGES
			);

			if (response.status === ResponseStatus.SUCCESS) {
				if (!previousMessagesMode) {
					togglePreviousMessagesMode(true);
				}

				setTimeout(() => {
					scrollToMessage(messageId, chatMessagesRef.current, chatScrollRef.current, true, true);
				}, 500);
			}
		}
	};

	const onScrollToMentionMessageHandler = async () => {
		const {0: firstMentionMessage} = mentionMessageArray;
		if (localMessages.find(lm => lm.id === firstMentionMessage.id)) {
			scrollToMessage(firstMentionMessage.id, chatMessagesRef.current, chatScrollRef.current, true);
			return;
		}

		if (previousMessagesMode) {
			const response = await getMessagesAround(
				chatScrollRef.current,
				setDisableScroll,
				setVisiblePreloader,
				firstMentionMessage.id,
				MAXIMUM_NUMBER_OF_MESSAGES
			);

			if (response.status === ResponseStatus.SUCCESS) {
				setTimeout(() => {
					scrollToMessage(
						firstMentionMessage.id,
						chatMessagesRef.current,
						chatScrollRef.current,
						true,
						false
					);
					removeUnrecordedMessage(firstMentionMessage.id);
					removeMentionMessage(firstMentionMessage.id);
				}, 500);
			}
		}
	};

	const onScrollHandler = useCallback(
		debounce(
			scrollEvent =>
				checkScrollTop(
					setDisableScroll,
					setVisiblePreloader,
					setNewMessagesCount,
					setScrollToBottom,
					resetStateAfterScrollToBottom,
					newMessagesCount,
					visiblePreloader,
					scrollToBottom,
					chatScrollRef.current,
					scrollEvent
				),
			100
		),
		[messages, newMessagesCount, visiblePreloader, chatScrollPositionBottom, previousMessagesMode]
	);

	const renderMessageItem = useCallback(
		(item: Message, index: number) => {
			const {id, type, pollText, mentionMessage, advertisement, reactions, sticker, talker, bet} =
				item;
			const pollMessageData: PollMessageData = pollText ? JSON.parse(pollText) : null;

			switch (type) {
				case MessageType.USER:
				case MessageType.ADVERTISEMENT:
				case MessageType.POLLRESULTS:
				case MessageType.STICKER:
				case MessageType.BET: {
					return (
						<ChatMessage
							key={id}
							message={item}
							mentionMessage={mentionMessage && !mentionMessage.deletedAt ? mentionMessage : null}
							highlightMessage={advertisement}
							pollResultsMessage={pollMessageData}
							stickerMessage={sticker}
							betMessage={JSON.parse(bet)}
							visibleMessageSubmenu={visibleSubmenu(id)}
							isLastMessage={id === lastViewedMessage}
							reactions={reactions}
							{...visibleMessageElements(index, item, localMessages)}
						/>
					);
				}
				case MessageType.VOTE: {
					if (talker.id === myTalker?.id && pollMessageData) {
						return (
							<div className='chat__message chat__message--poll' key={id} data-id={id}>
								<PollMessage pollMessageData={pollMessageData} />
							</div>
						);
					}
					return null;
				}
				default: {
					return null;
				}
			}
		},
		[localMessages, lastViewedMessage, submenuMessage]
	);

	useEffect(() => {
		setTimeout(() => {
			chatScrollRef.current?.scroll({top: chatScrollRef.current?.scrollHeight});
		}, 1000);
	}, []);

	useEffect(() => {
		if (isDesktop) {
			document.addEventListener('contextmenu', onContextMenuHandler);
		}

		return () => {
			if (isDesktop) {
				document.removeEventListener('contextmenu', onContextMenuHandler);
			}
		};
	}, [myTalker?.isBanned, userData?.isOnboard]);

	useEffect(() => {
		if (roomId) {
			document.addEventListener('click', onClickMessageReplayHandler);
		}
		return () => {
			document.removeEventListener('click', onClickMessageReplayHandler);
		};
	}, [roomId]);

	useEffect(() => {
		if (messages.length > 0) {
			const filteredMessages = messages.filter((message: Message) => checkMessage(message));
			setLocalMessages(filteredMessages);
			return;
		}
		setLocalMessages([]);
	}, [messages, isMyTalkerModer]);

	useEffect(() => {
		const lastMention = messages[messages.length - 1];
		const alwaysMentioned =
			lastMention && mentionMessageArray.find(item => item.id === lastMention.id);

		if (
			lastMention?.mentionMessage !== null &&
			lastMention?.talker.user?.id !== userData?.id &&
			alwaysMentioned === undefined
		) {
			const mentionedMessage = messages.find(
				message => message.id === lastMention.mentionMessage?.id && lastMention.isNotReaded
			);

			if (
				mentionedMessage &&
				mentionedMessage.talker.user &&
				mentionedMessage.talker.user.id === userData?.id &&
				chatScrollRef.current &&
				!chatScrollPositionBottom
			) {
				updateMentionMessageArray(lastMention);
			}
		}

		if (
			messages.length &&
			messages[messages.length - 1].isNotReaded &&
			lastMention?.type !== MessageType.VOTE &&
			lastMention?.talker.user?.id !== userData?.id &&
			chatScrollRef.current &&
			lastViewedMessage === null &&
			!chatScrollPositionBottom &&
			!visiblePreloader
		) {
			setLastViewedMessage(messages[messages.length - 2].id);
			window.parent.postMessage({type: 'haveUnreadedMessages'}, '*');
		}

		if (!messages[messages.length - 1]?.isNotReaded) {
			setLastViewedMessage(null);
		}
	}, [messages.length]);

	useEffect(() => {
		if (previousMessagesMode) {
			setNewMessagesCount(unrecordedMessages.length);

			if (unrecordedMessages.length > 0) {
				unrecordedMessages.forEach(unrecordedMessage => {
					if (
						unrecordedMessage.mentionMessage &&
						unrecordedMessage.mentionMessage.talker.user &&
						unrecordedMessage.mentionMessage.talker.user.id === userData?.id &&
						!mentionMessageArray.find(m => m.id === unrecordedMessage.id)
					) {
						updateMentionMessageArray(unrecordedMessage);
					}
				});
			}

			return;
		}

		if (messages.length && lastViewedMessage !== null) {
			setNewMessagesCount(
				messages.filter(
					item =>
						item.isVisible &&
						item.isNotReaded &&
						item.type !== MessageType.VOTE &&
						userData?.id !== item.talker.user.id
				).length
			);
		}
	}, [messages, unrecordedMessages, lastViewedMessage]);

	useEffect(() => {
		const checkLastMessageUser =
			localMessages.length &&
			localMessages[localMessages.length - 1].isNotReaded &&
			localMessages[localMessages.length - 1].talker.user &&
			localMessages[localMessages.length - 1].talker.user?.id === userData?.id;

		if (
			!disableScroll &&
			!previousMessagesMode &&
			(checkLastMessageUser || chatScrollPositionBottom)
		) {
			setTimeout(() => {
				chatScrollRef.current?.scroll({top: chatScrollRef.current?.scrollHeight});
			});
		}

		setDisableScroll(false);
	}, [localMessages]);

	useEffect(() => {
		if (messages.length && !chatScrollRef.current?.classList.contains('visible')) {
			chatScrollRef.current?.classList.add('visible');
		}
	}, [localMessages, chatScrollRef.current]);

	useEffect(() => {
		(async () => {
			const slowModeTime = slowMode.local.enable ? slowMode.local.time : slowMode.global.time;
			if (
				!isMyTalkerModer &&
				localMessages.length &&
				(slowMode.global.enable || slowMode.local.enable) &&
				!checkUserLastMessageForSlowMode
			) {
				setCheckUserLastMessageForSlowMode(true);

				if (
					!localMessages.find(m => m.talker.user.id === userData?.id) &&
					new Date().getTime() - new Date(localMessages[0].createdAt).getTime() <= slowModeTime
				) {
					if (roomId && userData?.id) {
						const response = await RoomService.getUserLastMessage(
							accessToken,
							roomId,
							userData?.id
						);
						if (response.status === ResponseStatus.SUCCESS) {
							if (response.data) {
								const timeDifference =
									new Date().getTime() - new Date(response.data.createdAt).getTime();
								if (timeDifference <= slowModeTime) {
									setSlowModeTimeLeft(slowModeTime);
									setslowModeTimeLeftOffset(timeDifference);
									toggleSlowModeCountdownEnable(true);
								}
							}
						}
					}
					return;
				}

				if (localMessages.find(m => m.talker.user.id === userData?.id)) {
					const myMessages = localMessages.filter(m => m.talker.user.id === userData?.id);
					const timeDifference =
						new Date().getTime() - new Date(myMessages[myMessages.length - 1].createdAt).getTime();
					if (timeDifference <= slowModeTime) {
						setSlowModeTimeLeft(slowModeTime);
						setslowModeTimeLeftOffset(timeDifference);
						toggleSlowModeCountdownEnable(true);
					}
				}
			}
		})();
	}, [isMyTalkerModer, localMessages, slowMode, checkUserLastMessageForSlowMode]);

	useEffect(() => {
		if (isInternernetActive && !chatScrollPositionBottom) {
			setTimeout(() => {
				chatScrollRef.current?.scroll({top: chatScrollRef.current?.scrollHeight});
			});
		}
	}, [isInternernetActive]);

	if (!localMessages.length) {
		return (
			<div
				className='chat'
				onClick={onShowButtonsHandler}
				onKeyDown={onShowButtonsHandler}
				role='button'
				tabIndex={0}>
				<ChatIntro />
			</div>
		);
	}

	return (
		<div
			className='chat'
			onClick={onShowButtonsHandler}
			onKeyDown={onShowButtonsHandler}
			role='button'
			tabIndex={0}>
			{pinnedMessage !== null && <PinnedMessage handleClick={onClickOnPinnedMessageHandler} />}
			<div className='chat__axis-y' onScroll={onScrollHandler} ref={chatScrollRef}>
				<ChatMessagesPreloader visible={visiblePreloader} />
				<div className={chatMessagesClasses} ref={chatMessagesRef}>
					{localMessages.map(renderMessageItem)}
				</div>
			</div>
			<div className='chat__scrollbtns'>
				{!!mentionMessageArray.length && (
					<Button onClick={onScrollToMentionMessageHandler} className='chat__mention' />
				)}
				{!chatScrollPositionBottom && (
					<div className='chat__unreaded-messages'>
						{!!newMessagesCount && (
							<span className='chat__unreaded-messages-text'>{newMessagesCount}</span>
						)}
						<Button
							onClick={onScrollToUnreadMessagesHandler}
							className='chat__unreaded-messages-btn'
							aria-label='scroll-to'
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default observer(Chat);
