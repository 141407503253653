import {MODAL_STYLE} from 'constants/constants';
import modalServices from 'store/modalServices';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import {Language} from 'components/Language';
import useL10n from 'l10n/useL10n';
import Button from 'components/hoc/Button';

const LanguageModal: React.FC = function LanguageModal() {
	const {languageScreen} = useL10n();
	const {languageModalVisible, toggleLanguageModal} = useLocalObservable(() => modalServices);

	return (
		<Modal
			className='modal'
			isOpen={languageModalVisible}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='modal__head'>
				<div className='modal__title'>{languageScreen.title}</div>
				<Button className='modal__close' onClick={() => toggleLanguageModal(false)}>
					close
				</Button>
			</div>
			<div className='modal__body'>
				<Language />
			</div>
		</Modal>
	);
};

export default observer(LanguageModal);
