import {USER_UNNAMED} from 'constants/constants';
import {encryptionUserName} from 'utils/encryptionUserName';
import classNames from 'classnames/bind';
import {FunctionComponent, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {Talker} from 'models/room';
import userServices from 'store/userServices';
import {ReactComponent as IcoHide} from 'assets/svg/ico-submenu-hide.svg';
import {ReactComponent as IcoShow} from 'assets/svg/ico-submenu-show.svg';
import {ReactComponent as IcoRemoveSpeaker} from 'assets/svg/ico-submenu-remove-speaker.svg';
import {ReactComponent as IcoAddSpeaker} from 'assets/svg/ico-speaker-add.svg';
import {ReactComponent as IcoBlock} from 'assets/svg/ico-submenu-block.svg';
import {ReactComponent as IcoUnBlock} from 'assets/svg/ico-submenu-unblock.svg';
import {ReactComponent as IcoMute} from 'assets/svg/ico-mute.svg';
import {ReactComponent as IcoReportUser} from 'assets/svg/ico-report-user.svg';

import AnonimAva from 'assets/images/anonim-ava.jpg';
import useSubmenuActions from 'hooks/useSubmenuActions';
import useAnalytics from 'hooks/useAnalytics';
import roomServices from 'store/roomServices';
import modalServices from 'store/modalServices';
import reportModalService from 'store/reportModalService';
import UserRole from 'models/enums/UserRole.enum';
import SocketIoServices from 'services/SocketIoServices';
import RoomService from 'services/api/RoomService';
import appService from 'store/appService';
import chatServices from 'store/chatServices';
import useL10n from 'l10n/useL10n';
import Button from 'components/hoc/Button';
import {Avatar} from 'components/avatar';

interface IUserItemProps {
	talker: Talker;
}

const UserItem: FunctionComponent<IUserItemProps> = function UserItem({talker}) {
	const {isModer: isTalkerModer, role: talkerRole, user} = talker;
	const {appReadOnly, appVoice, blockedUsers} = useLocalObservable(() => appService);
	const {userData, isUserExternalIdCorrect, accessToken} = useLocalObservable(() => userServices);
	const {roomId, messages, bannedTalkers, myTalker} = useLocalObservable(() => roomServices);
	const {hideVisibleModalUsers} = useLocalObservable(() => modalServices);
	const {showReportModal} = useLocalObservable(() => reportModalService);
	const {chatBot} = useLocalObservable(() => chatServices);
	const [showMore, setShowMore] = useState(false);
	const {blockUser, unBlockUser, messagesVisible, changeUserRole, blockUserByUser} =
		useSubmenuActions();
	const {chatUsersScreen} = useL10n();
	const {sendAnalytics} = useAnalytics();

	const foundMessages = !!messages.find(m => m.talker.user && m.talker.user?.id === user.id);
	const foundVisibleMessage = !!messages.find(
		m => m.talker.user && m.talker.user?.id === user.id && m.isVisible
	);

	const isMyTalkerModer = !!myTalker?.isModer;
	const isTalkerSpeaker = talkerRole === UserRole.SPEAKER;
	const isTalkerBanned = !!bannedTalkers.find(m => m.user && m.user.id === user.id);
	const isTalkerChatBot = talker.user && chatBot && talker.user.name === chatBot.name;

	const commonCheckForVisibleControls = userData && user && myTalker && userData.id !== user.id;
	const isVisibleControlsForModer = commonCheckForVisibleControls && myTalker.isModer;
	const isVisibleControlsForNoModer =
		commonCheckForVisibleControls && !myTalker.isModer && !isTalkerModer;

	const userControlsClass = classNames('users-modal__user-controls', {
		'users-modal__user-controls--active': showMore,
	});

	const iconClass = classNames('users-modal__user-icon', {
		'users-modal__user-icon--active': showMore,
	});

	const messagesVisibleHandler = () => {
		if (roomId && user) {
			messagesVisible(user, roomId, foundVisibleMessage);
		}
	};

	const blockUserHandler = () => {
		hideVisibleModalUsers();
		if (roomId && user) {
			isTalkerBanned ? unBlockUser(user, roomId) : blockUser(user);
		}
	};

	const setRoleHandler = () => {
		hideVisibleModalUsers();
		if (roomId && user) {
			changeUserRole(user, roomId, isTalkerSpeaker ? UserRole.GUEST : UserRole.SPEAKER);
		}
	};

	const muteUserHandler = () => {
		hideVisibleModalUsers();
		if (roomId && user?.id) {
			SocketIoServices.emitMute(roomId, true, user.id);
		}
	};

	const onReportUserHandler = () => {
		showReportModal({
			report: {
				talker,
				target: 'user',
				messageId: undefined,
				blockUserByUserAvailable: false,
			},
		});
	};

	const onBlockUserByUserHandler = async () => {
		const blockedUserFind = blockedUsers.find(item => item.id === user.id);
		if (blockedUserFind && blockedUserFind.id && accessToken) {
			sendAnalytics('unblocking_pressed');
			await RoomService.deleteBlockedUser(blockedUserFind.id, accessToken);
			return;
		}
		sendAnalytics('ban_pressed');
		blockUserByUser(talker.user);
	};

	return (
		<div className='users-modal__user'>
			<div className='users-modal__user-container'>
				<div className='users-modal__user-avatar'>
					<Avatar
						src={user?.pic ? user.pic : AnonimAva}
						alt={user.name && user.name !== '' ? encryptionUserName(user.name) : USER_UNNAMED}
						className='users-modal__user-avatar-img'
					/>
				</div>
				<div className='users-modal__user-wrapper'>
					<p className='users-modal__user-name'>
						<span className='users-modal__user-name-item'>
							{user.name && user.name !== '' ? encryptionUserName(user.name) : USER_UNNAMED}
						</span>
						{isTalkerModer && <span className='users-modal__user-admin'>admin</span>}
					</p>
					{!appReadOnly &&
						isUserExternalIdCorrect &&
						(isVisibleControlsForModer || isVisibleControlsForNoModer) && (
							<>
								<span className={iconClass} />
								<Button className='users-modal__user-btn' onClick={() => setShowMore(!showMore)}>
									show more
								</Button>
							</>
						)}
				</div>
			</div>

			{!appReadOnly &&
				isUserExternalIdCorrect &&
				isVisibleControlsForModer &&
				!isVisibleControlsForNoModer &&
				showMore && (
					<div className={userControlsClass}>
						{appVoice && !talker.isMuted && talker.role === UserRole.SPEAKER && (
							<div className='users-modal__user-controls-item'>
								<div className='users-modal__user-controls-texts'>
									<p className='users-modal__user-controls-title'>
										{chatUsersScreen.usersSubmenu.muteSpeaker.title}
									</p>
									<p className='users-modal__user-controls-subtitle'>
										{chatUsersScreen.usersSubmenu.muteSpeaker.subtitle}
									</p>
								</div>

								<IcoMute className='users-modal__user-controls-icon' />

								<Button className='users-modal__user-controls-action' onClick={muteUserHandler}>
									action
								</Button>
							</div>
						)}

						{appVoice && isMyTalkerModer && !isTalkerChatBot && (
							<div className='users-modal__user-controls-item'>
								<div className='users-modal__user-controls-texts'>
									<p className='users-modal__user-controls-title'>
										{isTalkerSpeaker
											? chatUsersScreen.usersSubmenu.setSpeaker.title
											: chatUsersScreen.usersSubmenu.removeFromSpeaker.title}
									</p>
									<p className='users-modal__user-controls-subtitle'>
										{isTalkerSpeaker
											? chatUsersScreen.usersSubmenu.setSpeaker.subtitle
											: chatUsersScreen.usersSubmenu.removeFromSpeaker.subtitle}
									</p>
								</div>

								{isTalkerSpeaker ? (
									<IcoRemoveSpeaker className='users-modal__user-controls-icon' />
								) : (
									<IcoAddSpeaker className='users-modal__user-controls-icon' />
								)}

								<Button className='users-modal__user-controls-action' onClick={setRoleHandler}>
									action
								</Button>
							</div>
						)}

						{foundMessages && (
							<div className='users-modal__user-controls-item'>
								<div className='users-modal__user-controls-texts'>
									<p className='users-modal__user-controls-title'>
										{foundVisibleMessage
											? chatUsersScreen.usersSubmenu.hideMessages.title
											: chatUsersScreen.usersSubmenu.showMessages.title}
									</p>
									<p className='users-modal__user-controls-subtitle'>
										{chatUsersScreen.usersSubmenu.showMessages.subtitle}
									</p>
								</div>

								{foundVisibleMessage ? (
									<IcoHide className='users-modal__user-controls-icon' />
								) : (
									<IcoShow className='users-modal__user-controls-icon' />
								)}

								<Button
									className='users-modal__user-controls-action'
									onClick={messagesVisibleHandler}>
									action
								</Button>
							</div>
						)}

						{!isTalkerModer && !isTalkerChatBot && (
							<div className='users-modal__user-controls-item'>
								<div className='users-modal__user-controls-texts'>
									{isTalkerBanned ? (
										<>
											<p className='users-modal__user-controls-title'>
												{chatUsersScreen.usersSubmenu.unblockUser.title}
											</p>
											<p className='users-modal__user-controls-subtitle'>
												{chatUsersScreen.usersSubmenu.unblockUser.subtitle}
											</p>
										</>
									) : (
										<>
											<p className='users-modal__user-controls-title'>
												{chatUsersScreen.usersSubmenu.blockUser.title}
											</p>
											<p className='users-modal__user-controls-subtitle'>
												{chatUsersScreen.usersSubmenu.blockUser.subtitle}
											</p>
										</>
									)}
								</div>

								{isTalkerBanned ? (
									<IcoShow className='users-modal__user-controls-icon' />
								) : (
									<IcoBlock className='users-modal__user-controls-icon' />
								)}

								<Button className='users-modal__user-controls-action' onClick={blockUserHandler} />
							</div>
						)}
					</div>
				)}

			{!appReadOnly &&
				isUserExternalIdCorrect &&
				!isVisibleControlsForModer &&
				isVisibleControlsForNoModer &&
				showMore && (
					<div className={userControlsClass}>
						<div className='users-modal__user-controls-item'>
							<div className='users-modal__user-controls-texts'>
								<p className='users-modal__user-controls-title'>
									{chatUsersScreen.usersSubmenu.reportUser.title}
								</p>
								<p className='users-modal__user-controls-subtitle'>
									{chatUsersScreen.usersSubmenu.reportUser.subtitle}
								</p>
							</div>
							<IcoReportUser className='users-modal__user-controls-icon users-modal__user-controls-icon--red' />
							<Button className='users-modal__user-controls-action' onClick={onReportUserHandler} />
						</div>

						{!isTalkerChatBot && (
							<div className='users-modal__user-controls-item'>
								<div className='users-modal__user-controls-texts'>
									{blockedUsers.find(u => u.id === user.id) ? (
										<>
											<p className='users-modal__user-controls-title'>
												{chatUsersScreen.usersSubmenu.unblockUserByUser.title}
											</p>
											<p className='users-modal__user-controls-subtitle'>
												{chatUsersScreen.usersSubmenu.unblockUserByUser.subtitle}
											</p>
										</>
									) : (
										<>
											<p className='users-modal__user-controls-title'>
												{chatUsersScreen.usersSubmenu.blockUserByUser.title}
											</p>
											<p className='users-modal__user-controls-subtitle'>
												{chatUsersScreen.usersSubmenu.blockUserByUser.subtitle}
											</p>
										</>
									)}
								</div>

								{blockedUsers.find(u => u.id === user.id) ? (
									<IcoUnBlock className='users-modal__user-controls-icon' />
								) : (
									<IcoBlock className='users-modal__user-controls-icon' />
								)}

								<Button
									className='users-modal__user-controls-action'
									onClick={onBlockUserByUserHandler}
								/>
							</div>
						)}
					</div>
				)}
		</div>
	);
};

export default observer(UserItem);
