/* eslint-disable react/no-danger */
import {MODAL_STYLE} from 'constants/constants';
import modalServices from 'store/modalServices';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import Button from 'components/hoc/Button';
import appService from 'store/appService';

const RulesModal: React.FC = function RulesModal() {
	const {rulesModalVisible, toggleRulesModal} = useLocalObservable(() => modalServices);
	const {rules} = useLocalObservable(() => appService);
	const translations = useL10n();
	const {sendAnalytics} = useAnalytics();

	const closeModalHandler = () => {
		sendAnalytics('rules_closed');
		toggleRulesModal(false);
	};

	return (
		<Modal
			className='modal'
			isOpen={rulesModalVisible}
			ariaHideApp={false}
			style={{
				overlay: {...MODAL_STYLE.overlay, zIndex: 30001},
			}}>
			<div className='modal__head'>
				<div className='modal__title'>{translations.rulesScreen.title}</div>
				<Button className='modal__close' onClick={closeModalHandler}>
					close
				</Button>
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<div className='modal__rules-text' dangerouslySetInnerHTML={{__html: rules}} />
				</div>
			</div>
		</Modal>
	);
};

export default observer(RulesModal);
